/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */
.sidebar_theme_alfa-on-color {
    color: rgba(255, 255, 255, 1);
    background-color: rgb(11, 31, 53);
}
@media screen and (max-width: 47.9375em) {
        .sidebar_theme_alfa-on-color .sidebar__header {
            background-color: rgb(11, 31, 53);
            border-bottom: 1px solid rgba(255, 255, 255, .07);
        }

        .sidebar_theme_alfa-on-color .sidebar__footer:after {
            background-image: -webkit-gradient(linear, left bottom, left top, from(#222a32), to(rgba(34, 42, 50, 0)));
            background-image: -webkit-linear-gradient(bottom, #222a32, rgba(34, 42, 50, 0));
            background-image: linear-gradient(to top, #222a32, rgba(34, 42, 50, 0));
        }
}

/*# sourceMappingURL=sidebar_theme_alfa-on-color.css.map */
