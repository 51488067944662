/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */
.sidebar_theme_alfa-on-white {
    color: rgba(11, 31, 53, .95);
    background-color: rgb(255, 255, 255);
}
@media screen and (max-width: 47.9375em) {
        .sidebar_theme_alfa-on-white .sidebar__header {
            background-color: rgb(255, 255, 255);
            border-bottom: 1px solid rgba(11, 31, 53, .07);
        }

        .sidebar_theme_alfa-on-white .sidebar__footer:after {
            background-image: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(255, 255, 255, 0)));
            background-image: -webkit-linear-gradient(bottom, #fff, rgba(255, 255, 255, 0));
            background-image: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
        }
}

/*# sourceMappingURL=sidebar_theme_alfa-on-white.css.map */
