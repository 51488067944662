/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

.icon-button {
    opacity: .9;
    overflow: hidden;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    cursor: pointer;
    outline: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    border-radius: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-transition-duration: 250ms;
            transition-duration: 250ms;
    -webkit-transition-property: background, opacity;
    transition-property: background, opacity;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
}

.icon-button, .icon-button *, .icon-button *:before, .icon-button *:after {
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
    }

.icon-button_hovered,
    .icon-button_focused {
        opacity: 1;
    }

.icon-button_disabled {
        opacity: .25;
        cursor: default;
    }

.icon-button_size_s {
        width: 30px;
        height: 30px;
    }

.icon-button_size_m {
        width: 34px;
        height: 40px;
    }

.icon-button_size_l {
        width: 44px;
        height: 50px;
    }

.icon-button_size_xl {
        width: 52px;
        height: 60px;
    }

/*# sourceMappingURL=icon-button.css.map */
