/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

body.sidebar-overlay {
    overflow: hidden;
}

.sidebar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    width: 392px;
    height: 100%;
    padding: 0;
    font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', 'Roboto Rouble', sans-serif;
    -webkit-transition: -webkit-transform 350ms ease-in-out;
    transition: -webkit-transform 350ms ease-in-out;
    transition: transform 350ms ease-in-out;
    transition: transform 350ms ease-in-out, -webkit-transform 350ms ease-in-out;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    z-index: 1001;
}

.sidebar, .sidebar *, .sidebar *:before, .sidebar *:after {
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
    }

.sidebar_visible {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

.sidebar__header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        min-height: 20px;
        margin-bottom: 50px;
    }

.sidebar__header-content {
        display: none;
    }

.sidebar__overlay {
        position: absolute;
        top: 0;
        left: -100vw;

        width: 100vw;
        height: 200vw;

        border: none;
        outline: none;

        content: '';

        visibility: hidden;

        -webkit-transition: all 350ms ease-in-out;

        transition: all 350ms ease-in-out;
        cursor: pointer;
    }

.sidebar__overlay_visible {
            visibility: visible;
            background-color: rgba(0, 0, 0, 0.6);
        }

.sidebar__closer {
        margin: -14px 0 0 -10px;
    }

.sidebar__inner {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        height: 100%;

        padding: 24px 24px 0;

        overflow-y: auto;
        overflow-x: hidden;
    }

.sidebar__content {
        position: relative;
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;

        width: 100%;
        padding-bottom: 35px;
    }

@media screen and (max-width: 47.9375em) {
    .sidebar {
        width: 100%;
        padding: 0;
        margin-right: 0;
    }

        .sidebar_visible {
            margin-right: 0;
            overflow: hidden;
        }

        .sidebar__header {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;

            display: -webkit-box;

            display: -ms-flexbox;

            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;

            width: 100%;
            height: 56px;
            padding: 15px 12px;
        }

        .sidebar__closer {
            margin: 0 0 0 -5px;
        }

        .sidebar__header-content {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: end;
                -ms-flex-pack: end;
                    justify-content: flex-end;
            width: 100%;

            padding-left: 20px;
            overflow: hidden;
        }

        .sidebar__inner {
            padding: 0;
        }

        .sidebar__footer {
            position: relative;
        }

            .sidebar__footer:after {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;

                width: 100%;
                height: 40px;

                content: '';
                pointer-events: none;
                -webkit-transform: translateY(-100%);
                        transform: translateY(-100%);
            }

        .sidebar__action {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
        }

        .sidebar__overlay {
            display: none;
        }

        .sidebar__content {
            position: relative;

            min-height: calc(100vh - 56px);
            padding: 77px 12px 35px;

            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            -webkit-transform: translateZ(0);
                    transform: translateZ(0);
        }

    body.sidebar-visible {
        position: fixed;
        overflow: hidden;
    }
}

/*# sourceMappingURL=sidebar.css.map */
