/* This Source Code Form is subject to the terms of the Mozilla Public
* License, v. 2.0. If a copy of the MPL was not distributed with this
* file, You can obtain one at http://mozilla.org/MPL/2.0/. */

.icon_size_l.icon_name_close.icon_theme_alfa-on-white {
    background-image: url('icon_close_l_black.svg');
}

.icon_size_l.icon_name_close.icon_theme_alfa-on-color {
    background-image: url('icon_close_l_white.svg');
}

.icon_size_m.icon_name_close.icon_theme_alfa-on-white {
    background-image: url('icon_close_m_black.svg');
}

.icon_size_m.icon_name_close.icon_theme_alfa-on-color {
    background-image: url('icon_close_m_white.svg');
}

.icon_size_s.icon_name_close.icon_theme_alfa-on-white {
    background-image: url('icon_close_s_black.svg');
}

.icon_size_s.icon_name_close.icon_theme_alfa-on-color {
    background-image: url('icon_close_s_white.svg');
}

.icon_size_xl.icon_name_close.icon_theme_alfa-on-white {
    background-image: url('icon_close_xl_black.svg');
}

.icon_size_xl.icon_name_close.icon_theme_alfa-on-color {
    background-image: url('icon_close_xl_white.svg');
}

.icon_size_xs.icon_name_close.icon_theme_alfa-on-white {
    background-image: url('icon_close_xs_black.svg');
}

.icon_size_xs.icon_name_close.icon_theme_alfa-on-color {
    background-image: url('icon_close_xs_white.svg');
}

/*# sourceMappingURL=close.css.map */
